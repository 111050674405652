import React from "react";
import styled from "styled-components";

/* Resume section is 2 columns. H2 is section title in left column H3 are different sections in right column
    probably individual jobs or experiences. 99% credit to Kitty. */

const ResumeSectionCon = styled.div`
  display: flex;
  margin-bottom: 3em;
  flex-direction: row;

  h2 {
    margin-bottom: 0;
    margin-top: 0;

    margin-top: revert;
    padding-right: 1.5em;

    text-align: right;
    border-right: 1px solid #367bb0;
    margin-right: 1.5em;
    flex: 0 0 7.5em;
  }

  div {
    padding-top: 0.9em;
  }

  div > :last-child {
    margin-bottom: 0;
  }

  div > :first-child {
    margin-top: 0;
  }
h3{
  margin-bottom: 0;
  + * {
    margin-top: 0;
  }
}
  h3.job {
    display: flex;

    span {
      margin-left: auto;
      font-weight: normal;
      display: block;
      padding-left: 1em;
      font-size: 80%;
      display: inline-block;
      text-align: right;
    }
  }

  li {
    margin: 0.5em 0;
  }

  @media screen and (max-width: 700px) {
    display: block;

    h2 {
      padding: revert;
      text-align: left;
      border-right: none;
      margin-right: 0;
      border-bottom: 1px dashed #367bb0;
    }
  }
`;

const ResumeSection = ({ children }) => <ResumeSectionCon>{children}</ResumeSectionCon>;

export default ResumeSection;