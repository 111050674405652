import React from "react";
import Layout from "../components/Layout/layout";
import { Helmet } from "react-helmet-async";
import ResumeSection from "../components/Resume/resume";

const ResumePage = ({ data }) => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Erin Olmon &middot; User Experience Research &amp; Design</title>
      <link rel="canonical" href="https://erinolmon.net" />
    </Helmet>
    <p>
      My goal is to create and improve people’s experiences with technology, to
      reduce friction, and add delight. Hopefully making life a little easier
      and brighter for people, and in turn, making the world a better place.
    </p>
    <p>
      To accomplish this goal, I help people explore complex design problems and
      create solutions that balance the needs of multiple stakeholders,
      including business product owners, developers, and, of course, the people
      using the solution.
    </p>
    <ResumeSection>
      <h2>Skills</h2>
      <div>
        <h3> User Experience Research and Design</h3>
        <p>
          Create user profiles, personas, user flows, and scenarios. Design
          interactions and design prototypes. Conduct user study activities such
          as card sorting, wants and needs analysis, task analysis, interviews,
          contextual inquiries, ethnographic studies, and surveys.
        </p>

        <h3>Effective in Teams</h3>
        <p>
          Effectively and efficiently collaborate with clients and developers to
          generate designs that meet business and user needs. Collaborate with
          design team members to design great user experiences.
        </p>

        <h3>Adept with Technology</h3>
        <p>
          Axure, Balsamiq, Morae, CSS, PHP, HTML, SQL, jQuery, SASS, Compass,
          git, Linux, WordPress, JAWS, VoiceOver, Adobe Creative Suite, and
          more.
        </p>
      </div>
    </ResumeSection>
    <ResumeSection>
      <h2>Tools</h2>
      <div>
        <ul>
          <li>Axure</li>
          <li>Sketch</li>
          <li>Invision</li>
          <li>Abstract</li>
          <li>Git</li>
          <li>Userzoom</li>
          <li>VS Code</li>
        </ul>
      </div>
    </ResumeSection>
    <ResumeSection>
      <h2>Experience</h2>
      <div>
        <h3 class="job">
          Fiserv &ndash; User Experience Designer <span>April 2019 – present</span>
        </h3>
        <h3 class="job">
          CenturyLink  &ndash; User Experience Designer<span>May 2010 – Jul 2015</span>
        </h3>
        <h3 class="job">
          University of Washington  &ndash; Instructor <span>Mar 2008 – Dec 2009</span>
        </h3>
      </div>
    </ResumeSection>
    <ResumeSection>
      <h2>Certifications</h2>
      <div>
        <h3>UX Master Certificate (UXMC)</h3>
        <h3>Certified Professional in Web Accessibility (CPWA)</h3>
        <h3>Certified Professional in Accessibility Core Competency (CPACC)</h3>
        <h3>Web Accessibility Specialist (WAS)</h3>
      </div>
    </ResumeSection>

    <ResumeSection>
      <h2>Education</h2>
      <div>
        <h3>
          Master of Science in{" "}
          <a href="https://www.hcde.washington.edu/">
            Human Centered Design and Engineering
          </a>
        </h3>
        <p>
          <a href="https://uw.edu">University of Washington</a>, Seattle, WA
        </p>
        <h3>Bachelor of Science in Computer Engineering</h3>
        <p>
          <a href="https://uc.edu">University of Cincinnati</a>, Cincinnati, OH
        </p>
      </div>
    </ResumeSection>
  </Layout>
);

export default ResumePage;