import React from "react";
import styled from "styled-components";
import { StaticQuery, Link, graphql } from "gatsby";
import MainNav from "../MainNav/mainnav";

const HeaderCon = styled.header`
  width: 100%;
  color: white;

  margin: 0 auto;
  max-width: 800px;
  border-bottom: 1px solid #555;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  background: linear-gradient(160deg, navy, indigo, purple, blueviolet);
    -webkit-background-clip: text;
    -moz-background-clip:text;
    -webkit-text-fill-color: transparent;
  
  h1 {
    font-size: 35px;
    font-weight: 500;
    color: white;
    display: inline;

    a {
      text-decoration: none;
      color: white;
    }
  }

  @media screen and (max-width: 860px) {
    margin: 0 2em;
    width: unset;
  }

  @media screen and (max-width: 600px) {
    display: flex;
    justify-content: center;
    flex-direction: column;

    h1 {
      text-align: center;
    }
  }
`;

const Skipnav = styled.a`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;

  :focus,
  :active {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: absolute;
    left: 0;
    top: 0;
    white-space: normal;
    width: auto;
    font-size: 1.25rem;
    border: 3px solid black;
    padding: 1em;
  }
`;

const Header = () => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={(data) => (
      <HeaderCon>
        <Skipnav href="#main">Skip to Main Content</Skipnav>
          <h1>
            <Link
              to="/"
            >
              {data.site.siteMetadata.title}
            </Link>
          </h1>
          <MainNav />
      </HeaderCon>
    )}
  />
);

export default Header;
