import React from "react";
import styled from "styled-components";
import {Link} from "gatsby";

const NavCon = styled.nav`
  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
  }

  li {
    padding: 0 0.5rem;
    a{
      color: white;
      border-color: purple;
    }
  }
`;

const ListLink = (props) => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
);

const MainNav = () => (
    <NavCon aria-label="Main Navigation">
        <ul>
            <ListLink to="/">Home</ListLink>
            <ListLink to="/about/">About</ListLink>
            <ListLink to="/resume/">Résumé</ListLink>
        </ul>
    </NavCon>
);

export default MainNav;
