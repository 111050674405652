import React from "react";
import styled from "styled-components";
import Header from "../Header/header";
import Footer from "../Footer/footer";

/* 3-row flexbox with header, main, and footer; ensures footer is at bottom if page in less than full height */

const LayoutCon = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;

const Main = styled.main`
  flex: 1;
  max-width: 800px;
  padding: 0;
  margin: 0 auto;

  @media screen and (max-width: 860px) {
    padding: 0 2em;
    margin: 0 auto;  
  }
`;

const Layout = ({ children }) => (
  <LayoutCon>
    <Header />
    <Main id="main">{children}</Main>
    <Footer />
  </LayoutCon>
);

export default Layout;