import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const FooterCon = styled.footer`
  background: #3a3a3a;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  height: 6em;

  div {
    max-width: 1240px;
    padding: 0 20px;
    margin: 0 auto;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    p,
    a {
      font-size: 15px;
    }
  }

  nav {
    float: right;
  }

  ul {
    display: flex;
    list-style-type: none;
    align-items: center;
    justify-content: space-between;
  }

  a {
    color: white;
  }

  li {
    display: inline-block;
    margin-right: 1rem;
  }
  p {
    display: inline;
    color: white;
    align-items: center;
  }
`;

const ListLink = (props) => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
);

const Footer = () => (
  <FooterCon>
    <div>
      <p>
        copyright &copy; {new Date().getFullYear()} &middot; All rights
        reserved.
      </p>
      <nav aria-label="Site Information Navigation">
        <ul>
          <ListLink to="/accessibility/">Accessibility</ListLink>
        </ul>
      </nav>
    </div>
  </FooterCon>
);

export default Footer;
